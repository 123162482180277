export const FancyChip = () => {
  return (
    <svg className="fancy-chip" viewBox="0 0 340 200" overflow="visible">
      <defs>
        <filter id="glow">
          <feGaussianBlur stdDeviation="3" result="coloredBlur" />
          <feMerge>
            <feMergeNode in="coloredBlur" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g className="files">
        <g className="file">
          <polygon points="-102.1,124 -172.9,83.1 -279.1,144.4 -231.9,171.7 -184.7,171.7 		" />
          <line x1="-184.7" y1="103.5" x2="-137.5" y2="130.8" />
          <line x1="-196.5" y1="110.3" x2="-149.3" y2="137.6" />
          <line x1="-208.3" y1="117.1" x2="-161.1" y2="144.4" />
          <line x1="-220.1" y1="124" x2="-172.9" y2="151.2" />
          <polygon points="-208.3,158 -231.9,171.7 -184.7,171.7 		" />
        </g>
        <g className="file">
          <polygon points="-326.4,144.6 -397.2,103.7 -503.4,165.1 -456.2,192.3 -409,192.3 		" />
          <line x1="-409" y1="124.2" x2="-361.8" y2="151.4" />
          <line x1="-420.8" y1="131" x2="-373.6" y2="158.3" />
          <line x1="-432.6" y1="137.8" x2="-385.4" y2="165.1" />
          <line x1="-444.4" y1="144.6" x2="-397.2" y2="171.9" />
          <polygon points="-432.6,178.7 -456.2,192.3 -409,192.3 		" />
        </g>
        <g className="file">
          <polygon points="-231.9,90.6 -302.8,131.5 -409,70.2 -361.8,42.9 -314.6,42.9 		" />
          <line x1="-314.6" y1="111.1" x2="-267.3" y2="83.8" />
          <line x1="-326.4" y1="104.3" x2="-279.1" y2="77" />
          <line x1="-338.2" y1="97.5" x2="-290.9" y2="70.2" />
          <line x1="-350" y1="90.6" x2="-302.8" y2="63.4" />
          <polygon points="-338.2,56.6 -361.8,42.9 -314.6,42.9 		" />
        </g>
        <g className="file">
          <polygon points="1095.1,246.2 1024.2,205.3 918,266.6 965.2,293.9 1012.4,293.9 		" />
          <line x1="1012.4" y1="225.7" x2="1059.6" y2="253" />
          <line x1="1000.6" y1="232.5" x2="1047.8" y2="259.8" />
          <line x1="988.8" y1="239.4" x2="1036" y2="266.6" />
          <line x1="977" y1="246.2" x2="1024.2" y2="273.4" />
          <polygon points="988.8,280.3 965.2,293.9 1012.4,293.9 		" />
        </g>
        <g className="file">
          <polygon points="1189.5,192.2 1118.7,233.1 1012.4,171.7 1059.6,144.5 1106.9,144.5 		" />
          <line x1="1106.9" y1="212.6" x2="1154.1" y2="185.4" />
          <line x1="1095.1" y1="205.8" x2="1142.3" y2="178.6" />
          <line x1="1083.3" y1="199" x2="1130.5" y2="171.7" />
          <line x1="1071.4" y1="192.2" x2="1118.7" y2="164.9" />
          <polygon points="1083.2,158.1 1059.6,144.5 1106.9,144.5 		" />
        </g>
        <g className="file">
          <polygon points="1461,353.4 1390.2,394.3 1283.9,333 1331.1,305.7 1378.4,305.7 		" />
          <line x1="1378.4" y1="373.8" x2="1425.6" y2="346.6" />
          <line x1="1366.5" y1="367" x2="1413.8" y2="339.8" />
          <line x1="1354.7" y1="360.2" x2="1402" y2="333" />
          <line x1="1342.9" y1="353.4" x2="1390.2" y2="326.1" />
          <polygon points="1354.7,319.3 1331.1,305.7 1378.4,305.7 		" />
        </g>
        <g className="file">
          <polygon points="1236.7,332.7 1165.9,373.6 1059.6,312.3 1106.9,285 1154.1,285 		" />
          <line x1="1154.1" y1="353.2" x2="1201.3" y2="325.9" />
          <line x1="1142.3" y1="346.4" x2="1189.5" y2="319.1" />
          <line x1="1130.5" y1="339.6" x2="1177.7" y2="312.3" />
          <line x1="1118.7" y1="332.7" x2="1165.9" y2="305.5" />
          <polygon points="1130.4,298.7 1106.9,285 1154.1,285 		" />
        </g>
        <g className="file">
          <polygon points="1301.6,526 1230.8,485.1 1124.6,546.5 1171.8,573.7 1219,573.7 		" />
          <line x1="1219" y1="505.6" x2="1266.2" y2="532.8" />
          <line x1="1207.2" y1="512.4" x2="1254.4" y2="539.7" />
          <line x1="1195.4" y1="519.2" x2="1242.6" y2="546.5" />
          <line x1="1183.6" y1="526" x2="1230.8" y2="553.3" />
          <polygon points="1195.4,560.1 1171.8,573.7 1219,573.7 		" />
        </g>
        <g className="file">
          <polygon points="-420.8,485.2 -350,526 -243.7,464.7 -290.9,437.5 -338.2,437.5 		" />
          <line x1="-338.2" y1="505.6" x2="-385.4" y2="478.3" />
          <line x1="-326.4" y1="498.8" x2="-373.6" y2="471.5" />
          <line x1="-314.6" y1="492" x2="-361.8" y2="464.7" />
          <line x1="-302.8" y1="485.2" x2="-350" y2="457.9" />
          <polygon points="-314.5,451.1 -290.9,437.5 -338.2,437.5 		" />
        </g>
      </g>
      <g className="person">
        <path
          d="M157.6,423.8c3.2,1.9,3.2,4.9,0,6.8s-8.6,1.9-11.8,0s-3.2-4.9,0-6.8c3.1-1.8,8-1.9,11.2-0.3l71.4-40.6v-27.2
		l-59-34.1l-46.8-0.3l-59,34.1v27.3l36,20.6c3.3-1.6,8.2-1.5,11.2,0.3c3.2,1.9,3.2,4.9,0,6.8s-8.6,1.9-11.8,0s-3.2-4.9,0-6.8"
        />
        <path d="M283.1,291.9c-16.3-7.8-40.7-7.3-55.9,1.5c-16.1,9.3-16.1,24.6,0,33.9s42.6,9.3,58.7,0s16.1-24.6,0-33.9" />
      </g>
      <g className="bubbles">
        <g className="bubble">
          <polygon points="370.1,341.9 346.5,341.9 333.2,349.6 311.1,335.4 310.1,362.9 290.4,374.3 287.4,389.6 346.5,423.7 370.1,423.7 429.1,389.6 429.1,376 		" />
          <line x1="353.5" y1="359.1" x2="400.3" y2="386.3" />
          <line x1="341.7" y1="366.1" x2="376.7" y2="386.5" />
          <line x1="329" y1="372.7" x2="375.7" y2="400" />
          <line x1="317.2" y1="379.5" x2="352.1" y2="400" />
        </g>
        <g className="bubble">
          <polygon
            points="139.9,618.2 163.5,618.2 176.8,610.5 198.9,624.7 199.9,597.2 219.6,585.8 222.5,570.5 163.5,536.4 
			139.9,536.4 80.9,570.5 80.9,584.1 		"
          />
          <line x1="156.5" y1="553.1" x2="109.7" y2="580.3" />
          <line x1="156.7" y1="567.1" x2="121.7" y2="587.6" />
          <line x1="181" y1="566.7" x2="134.2" y2="594" />
          <line x1="181.2" y1="580.6" x2="146.3" y2="601" />
        </g>
        <g className="bubble">
          <polygon
            points="-89.3,594.5 -136.6,594.5 -237.8,536.2 -243.7,505.5 -125.7,437.3 -78.5,437.3 28.7,499.1 
			28.7,526.4 4.9,540.1 39.6,560.1 -30.5,560.5 		"
          />
          <line x1="-112.5" y1="553.8" x2="-19" y2="499.3" />
          <line x1="-136.1" y1="539.8" x2="-54.9" y2="492.3" />
          <line x1="-161.6" y1="526.6" x2="-68.1" y2="472" />
          <line x1="-185.2" y1="512.9" x2="-102.1" y2="464.3" />
        </g>
        <g className="bubble">
          <polygon
            points="760.5,225.8 713.9,226.1 701.9,233.1 654.4,233.1 607.6,205.7 607.6,178.5 655,151.2 702.3,151.2 
			749.4,178.3 749.4,205.7 737.5,212.5 		"
          />
          <line x1="702.3" y1="206.3" x2="678.3" y2="192" />
          <path d="M656.9,185.5c3.3,1.6,8.2,1.5,11.2-0.3c3.2-1.9,3.2-4.9,0-6.8s-8.6-1.9-11.8,0s-3.2,4.9,0,6.8" />
        </g>
        <g className="bubble">
          <polygon
            points="706.1,123.6 752.6,124 811.8,158.1 859.3,158.1 906.1,130.7 906.1,103.6 811.5,49.1 764.2,49.1 
			717.2,76.1 717.2,103.5 729,110.3 		"
          />
          <line x1="776.2" y1="69.5" x2="870.5" y2="123.9" />
          <line x1="763.8" y1="76.4" x2="846.9" y2="124.4" />
          <line x1="787.4" y1="62.5" x2="870.5" y2="110.5" />
          <line x1="740.2" y1="92.5" x2="823.3" y2="140.5" />
          <line x1="751.3" y1="83.3" x2="845.7" y2="137.7" />
        </g>
        <g className="bubble">
          <polygon
            points="27.9,580.5 -19.6,580.6 -66.3,608 -66.3,635.1 -43,648.6 -66.8,662.4 -19,662.4 28.2,689.6 
			75.5,689.6 86.8,683.1 110.5,669.5 122.5,662.6 122.5,635.1 		"
          />
          <line x1="63.5" y1="669.2" x2="-30.8" y2="614.8" />
          <path d="M-30.8,628.2" />
          <path d="M52.3,676.2" />
          <path d="M14.8,600.3" />
          <path d="M97.9,648.3" />
          <line x1="87.1" y1="655.4" x2="-7.3" y2="601" />
        </g>
        <g className="bubble">
          <polygon
            points="500.5,144.4 524.1,130.8 535.3,137.3 607.2,137.4 653.9,110.1 653.9,69.4 606.7,42.2 535.9,42.2 
			488.6,69.5 488.6,110.3 500.5,117.1 		"
          />
          <polyline points="571.6,61.9 595.2,61.9 607.2,68.9 607.2,82.2 595.2,89.2 571.6,89.2 559.8,96 		" />
          <path d="M538.3,102.5c3.3-1.6,8.2-1.5,11.2,0.3c3.2,1.9,3.2,4.9,0,6.8s-8.6,1.9-11.8,0s-3.2-4.9,0-6.8" />
        </g>
      </g>
      <g className="wires">
        <polyline points="4.2,130.8 -161.1,226.2 -538.8,226.2 -1011,498.8 -1011,757.8 	" />
        <path
          d="M-67.2,198.6c-3.3-1.6-8.2-1.5-11.2,0.3c-3.2,1.9-3.2,4.9,0,6.8s8.6,1.9,11.8,0c3.2-1.9,3.2-4.9,0-6.8
		l94.4-54.5"
        />
        <path d="M-20.2,225.9c-3.3-1.6-8.2-1.5-11.2,0.3c-3.2,1.9-3.2,4.9,0,6.8s8.6,1.9,11.8,0s3.2-4.9,0-6.8l94.4-54.5" />
        <polyline points="51.4,158 -113.9,253.4 -491.6,253.4 -963.8,526 -963.9,757.8 	" />
        <polyline points="97.5,185.9 -66.7,280.7 -444.4,280.7 -919.5,555 -919.5,756 	" />
        <polyline points="122.1,198.9 -43.1,294.3 -425.1,296.8 -893,566.9 -893,756 	" />
        <polyline points="4.1,76.3 -172.9,-26 -573.6,-25.6 -869.3,-196.3 -869.3,-345.1 	" />
        <polyline points="27.8,62.6 -161.1,-46.4 -562.4,-46.4 -833.9,-203.2 -833.9,-345.1 	" />
        <polyline points="52.4,49 -149.3,-66.8 -550.6,-66.9 -798.9,-209.7 -798.9,-345.1 	" />
        <polyline points="75.7,35.4 -125.7,-80.5 -137.5,-87.3 -538.8,-87.3 -763.1,-216.8 -763.1,-345.1 	" />
        <polyline points="-727.7,-345.1 -727.7,-223.6 -527,-107.8 -125.7,-107.8 99,22 	" />
        <path d="M122.4,8.5L-43.1-87.3c3.2-1.9,3.2-4.9,0-6.8s-8.6-1.9-11.8,0s-3.2,4.9,0,6.8c3.1,1.8,8,1.9,11.2,0.3" />
        <path
          d="M-31.8-107.5c-3.2,1.9-3.2,4.9,0,6.8s8.6,1.9,11.8,0s3.2-4.9,0-6.8c-3.1-1.8-8-1.9-11.2-0.3l-35.4-20.4h-448.6
		l-174.1-100.5v-116.4"
        />
        <path
          d="M-8.2-121.1c-3.2,1.9-3.2,4.9,0,6.8s8.6,1.9,11.8,0s3.2-4.9,0-6.8c-3.1-1.8-8-1.9-11.2-0.3l-47.2-27.3
		l-447.5,0.6L-656.8-239v-106.2"
        />
        <path
          d="M15.4-134.7c-3.2,1.9-3.2,4.9,0,6.8c3.2,1.9,8.6,1.9,11.8,0c3.2-1.9,3.2-4.9,0-6.8c-3.1-1.8-8-1.9-11.2-0.3
		l-59-34.1l-446.8,1l-131.6-76v-101.1"
        />
        <path d="M-6.8-94.4c3.3-1.6,8.2-1.5,11.2,0.3c3.2,1.9,3.2,4.9,0,6.8s-8.6,1.9-11.8,0s-3.2-4.9,0-6.8" />
        <path d="M16.4-108c3.3-1.6,8.2-1.5,11.2,0.3c3.2,1.9,3.2,4.9,0,6.8s-8.6,1.9-11.8,0s-3.2-4.9,0-6.8" />
        <path d="M40.1-121.6c3.3-1.6,8.2-1.5,11.2,0.3c3.2,1.9,3.2,4.9,0,6.8s-8.6,1.9-11.8,0s-3.2-4.9,0-6.8" />
        <path d="M-31.3,110.4c3.3-1.6,8.2-1.5,11.2,0.3c3.2,1.9,3.2,4.9,0,6.8s-8.6,1.9-11.8,0s-3.2-4.9,0-6.8" />
        <path d="M-99.1,150.9c3.3-1.6,8.2-1.5,11.2,0.3c3.2,1.9,3.2,4.9,0,6.8s-8.6,1.9-11.8,0s-3.2-4.9,0-6.8" />
        <path d="M-148.7,179.5c3.3-1.6,8.2-1.5,11.2,0.3c3.2,1.9,3.2,4.9,0,6.8c-3.2,1.9-8.6,1.9-11.8,0s-3.2-4.9,0-6.8" />
        <path
          d="M-185.3,62.9c-3.3,1.6-8.2,1.5-11.2-0.3c-3.2-1.9-3.2-4.9,0-6.8c3.2-1.9,8.6-1.9,11.8,0s3.2,4.9,0,6.8
		l23.6-0.2l82.9,47.9v27.3c3.3-1.6,8.2-1.5,11.2,0.3c3.2,1.9,3.2,4.9,0,6.8c-3.2,1.9-8.6,1.9-11.8,0s-3.2-4.9,0-6.8"
        />
        <path d="M-172.3,193.2c3.3-1.6,8.2-1.5,11.2,0.3c3.2,1.9,3.2,4.9,0,6.8c-3.2,1.9-8.6,1.9-11.8,0s-3.2-4.9,0-6.8" />
        <path
          d="M-55.5,124.2c-3.2,1.9-3.2,4.9,0,6.8c3.2,1.9,8.6,1.9,11.8,0s3.2-4.9,0-6.8c-3.1-1.8-8-1.9-11.2-0.3V96.7
		l-94.4-54.5h-70.8c-3.3,1.6-8.2,1.5-11.2-0.3c-3.2-1.9-3.2-4.9,0-6.8c3.2-1.9,8.6-1.9,11.8,0s3.2,4.9,0,6.8"
        />
        <polyline points="334.7,130.8 606.2,287.5 606.2,546.5 977,756.1 	" />
        <polyline points="311.1,144.4 570.7,294.3 573.7,568.6 898.3,756.1 	" />
        <polyline points="289,158.9 534.8,301.5 534.8,587.1 827.5,756.1 	" />
        <polyline points="263.8,171.8 499.9,308 503.8,609.5 756.1,756.1 	" />
        <polyline points="240.6,186.7 464.4,314.9 464.4,630 687.1,756.1 	" />
        <path
          d="M26.9,335.2c-3.2-1.9-3.2-4.9,0-6.8s8.6-1.9,11.8,0s3.2,4.9,0,6.8c-3.1,1.8-8,1.9-11.2,0.3v-0.1l-35.1,20.3
		h-377.9l-413.1,238.5l-0.4,160.1"
        />
        <path
          d="M168.3,254.2c3.2,1.9,3.2,4.9,0,6.8s-8.6,1.9-11.8,0s-3.2-4.9,0-6.8c3.1-1.8,8-1.9,11.2-0.3l71.9-41.6
		l-23.1-13.4"
        />
        <path
          d="M-8.2,328.1c-3.3-1.6-8.2-1.5-11.2,0.3c-3.2,1.9-3.2,4.9,0,6.8s8.6,1.9,11.8,0s3.2-4.9,0-6.8l47.8-26.9h35
		c3.3-1.6,8.2-1.5,11.2,0.3c3.2,1.9,3.2,4.9,0,6.8s-8.6,1.9-11.8,0c-3.2-1.9-3.2-4.9,0-6.8"
        />
        <path
          d="M109.7,281c-3.2,1.9-3.2,4.9,0,6.8s8.6,1.9,11.8,0c3.2-1.9,3.2-4.9,0-6.8c-3.1-1.8-8-1.9-11.2-0.3H27.8
		l-59,34.1l-379.7,1.1l-458.4,265v175"
        />
        <path
          d="M204.3,464.4c-3.2-1.9-3.2-4.9,0-6.8s8.6-1.9,11.8,0s3.2,4.9,0,6.8c-3.1,1.8-8,1.9-11.2,0.3l-23.6,13.6
		l-47.1,0.1L-43.2,376.2l-330.4-1.8l-401.3,233.4v146.5"
        />
        <path
          d="M-745.4,754.3V619.7l383.7-223.1h306.8l177.1,102.2h70.9l59-34.1v-20.1c-3.3,1.6-8.2,1.5-11.2-0.3
		c-3.2-1.9-3.2-4.9,0-6.8s8.6-1.9,11.8,0s3.2,4.9,0,6.8"
        />
        <path
          d="M299.8,471.5c3.2-1.9,3.2-4.9,0-6.8s-8.6-1.9-11.8,0s-3.2,4.9,0,6.8c3.1,1.8,8,1.9,11.2,0.3V492l-47,27.3
		L251.6,628l221.8,128.1"
        />
        <path
          d="M615,756.1L299.3,573.8l-0.1-27.2l47.1-27.4v-20.1c-3.3,1.6-8.2,1.5-11.2-0.3c-3.2-1.9-3.2-4.9,0-6.8
		s8.6-1.9,11.8,0s3.2,4.9,0,6.8"
        />
        <path
          d="M251.5,491.6c-3.2-1.9-3.2-4.9,0-6.8s8.6-1.9,11.8,0c3.2,1.9,3.2,4.9,0,6.8c-3.1,1.8-8,1.9-11.2,0.3
		l-47.3,27.3h-94.4L-66.7,417H-350l-365.9,211.3v126"
        />
        <path
          d="M298.7,519c-3.2-1.9-3.2-4.9,0-6.8c3.2-1.9,8.6-1.9,11.8,0c3.2,1.9,3.2,4.9,0,6.8c-3.1,1.8-8,1.9-11.2,0.3
		L275.6,533v66.4L544,756.1"
        />
        <polyline points="218.6,7.8 452.7,-128.2 1330.5,-127.8 1793.6,-392.3 	" />
        <path
          d="M1459.7-66.8c-3.2-1.6-8.1-1.5-11.2,0.3c-3.2,1.9-3.2,4.9,0,6.8s8.6,1.9,11.8,0c3.2-1.9,3.2-4.9,0-6.8
		l570.3-325.8"
        />
        <path d="M1496-59.7c-3.2-1.9-3.2-4.9,0-6.8c3.2-1.9,8.6-1.9,11.8,0c3.2,1.9,3.2,4.9,0,6.8c-3,1.8-7.9,1.9-11.2,0.3" />
        <path d="M1519.2-46.1c-3.2-1.9-3.2-4.9,0-6.8c3.2-1.9,8.6-1.9,11.8,0c3.2,1.9,3.2,4.9,0,6.8c-3,1.8-7.9,1.9-11.2,0.3" />
        <path d="M1542.9-32.5c-3.2-1.9-3.2-4.9,0-6.8c3.2-1.9,8.6-1.9,11.8,0c3.2,1.9,3.2,4.9,0,6.8c-3,1.8-7.9,1.9-11.2,0.3" />
        <polyline points="1841.2,-392.3 1342.3,-107.4 464.2,-108.1 242.3,21.4 	" />
        <polyline points="1889.5,-392.3 1354.1,-87 477.8,-87.3 265.8,35 	" />
        <polyline points="1936.7,-392.3 1365.9,-66.5 486.9,-66.9 289.5,48.7 	" />
        <polyline points="1983.5,-392.3 1377.7,-46 498.1,-46.4 313,62.3 	" />
        <path
          d="M1459.7-66.8c-3.2-1.6-8.1-1.5-11.2,0.3c-3.2,1.9-3.2,4.9,0,6.8s8.6,1.9,11.8,0c3.2-1.9,3.2-4.9,0-6.8
		l570.3-325.8"
        />
        <path
          d="M1471.6-46.3c-3.2-1.9-3.2-4.9,0-6.8c3.2-1.9,8.6-1.9,11.8,0c3.2,1.9,3.2,4.9,0,6.8c-3.1,1.8-8,1.9-11.2,0.3
		l-35.4,20.4L511.7-26L336.6,75.9"
        />
        <path
          d="M1040,756.1L641.6,526V280.7L439.9,164.3l1-122.1l82.7-47.7l925,0.4l47.2-27.3c3.2,1.6,8.1,1.5,11.2-0.3
		c3.2-1.9,3.2-4.9,0-6.8c-3.2-1.9-8.6-1.9-11.8,0c-3.2,1.9-3.2,4.9,0,6.8"
        />
        <path
          d="M1110.8,756.1L677,505.6V273.9L464.4,151.2l0.1-95.4l69.3-41.7l926.6,1.2l59-34.1c3.2,1.6,8.1,1.5,11.2-0.3
		c3.2-1.9,3.2-4.9,0-6.8c-3.2-1.9-8.6-1.9-11.8,0c-3.2,1.9-3.2,4.9,0,6.8"
        />
        <path d="M1496-59.7c-3.2-1.9-3.2-4.9,0-6.8c3.2-1.9,8.6-1.9,11.8,0c3.2,1.9,3.2,4.9,0,6.8c-3,1.8-7.9,1.9-11.2,0.3" />
        <path d="M1519.2-46.1c-3.2-1.9-3.2-4.9,0-6.8c3.2-1.9,8.6-1.9,11.8,0c3.2,1.9,3.2,4.9,0,6.8c-3,1.8-7.9,1.9-11.2,0.3" />
        <path d="M1542.9-32.5c-3.2-1.9-3.2-4.9,0-6.8c3.2-1.9,8.6-1.9,11.8,0c3.2,1.9,3.2,4.9,0,6.8c-3,1.8-7.9,1.9-11.2,0.3" />
      </g>
      <g className="wires--active">
        <polyline points="4.2,130.8 -161.1,226.2 -538.8,226.2 -1011,498.8 -1011,757.8 	" />
        <path
          d="M-67.2,198.6c-3.3-1.6-8.2-1.5-11.2,0.3c-3.2,1.9-3.2,4.9,0,6.8s8.6,1.9,11.8,0c3.2-1.9,3.2-4.9,0-6.8
		l94.4-54.5"
        />
        <path d="M-20.2,225.9c-3.3-1.6-8.2-1.5-11.2,0.3c-3.2,1.9-3.2,4.9,0,6.8s8.6,1.9,11.8,0s3.2-4.9,0-6.8l94.4-54.5" />
        <polyline points="51.4,158 -113.9,253.4 -491.6,253.4 -963.8,526 -963.9,757.8 	" />
        <polyline points="97.5,185.9 -66.7,280.7 -444.4,280.7 -919.5,555 -919.5,756 	" />
        <polyline points="122.1,198.9 -43.1,294.3 -425.1,296.8 -893,566.9 -893,756 	" />
        <polyline points="4.1,76.3 -172.9,-26 -573.6,-25.6 -869.3,-196.3 -869.3,-345.1 	" />
        <polyline points="27.8,62.6 -161.1,-46.4 -562.4,-46.4 -833.9,-203.2 -833.9,-345.1 	" />
        <polyline points="52.4,49 -149.3,-66.8 -550.6,-66.9 -798.9,-209.7 -798.9,-345.1 	" />
        <polyline points="75.7,35.4 -125.7,-80.5 -137.5,-87.3 -538.8,-87.3 -763.1,-216.8 -763.1,-345.1 	" />
        <polyline points="-727.7,-345.1 -727.7,-223.6 -527,-107.8 -125.7,-107.8 99,22 	" />
        <path d="M122.4,8.5L-43.1-87.3c3.2-1.9,3.2-4.9,0-6.8s-8.6-1.9-11.8,0s-3.2,4.9,0,6.8c3.1,1.8,8,1.9,11.2,0.3" />
        <path
          d="M-31.8-107.5c-3.2,1.9-3.2,4.9,0,6.8s8.6,1.9,11.8,0s3.2-4.9,0-6.8c-3.1-1.8-8-1.9-11.2-0.3l-35.4-20.4h-448.6
		l-174.1-100.5v-116.4"
        />
        <path
          d="M-8.2-121.1c-3.2,1.9-3.2,4.9,0,6.8s8.6,1.9,11.8,0s3.2-4.9,0-6.8c-3.1-1.8-8-1.9-11.2-0.3l-47.2-27.3
		l-447.5,0.6L-656.8-239v-106.2"
        />
        <path
          d="M15.4-134.7c-3.2,1.9-3.2,4.9,0,6.8c3.2,1.9,8.6,1.9,11.8,0c3.2-1.9,3.2-4.9,0-6.8c-3.1-1.8-8-1.9-11.2-0.3
		l-59-34.1l-446.8,1l-131.6-76v-101.1"
        />
        <path d="M-6.8-94.4c3.3-1.6,8.2-1.5,11.2,0.3c3.2,1.9,3.2,4.9,0,6.8s-8.6,1.9-11.8,0s-3.2-4.9,0-6.8" />
        <path d="M16.4-108c3.3-1.6,8.2-1.5,11.2,0.3c3.2,1.9,3.2,4.9,0,6.8s-8.6,1.9-11.8,0s-3.2-4.9,0-6.8" />
        <path d="M40.1-121.6c3.3-1.6,8.2-1.5,11.2,0.3c3.2,1.9,3.2,4.9,0,6.8s-8.6,1.9-11.8,0s-3.2-4.9,0-6.8" />
        <path d="M-31.3,110.4c3.3-1.6,8.2-1.5,11.2,0.3c3.2,1.9,3.2,4.9,0,6.8s-8.6,1.9-11.8,0s-3.2-4.9,0-6.8" />
        <path d="M-99.1,150.9c3.3-1.6,8.2-1.5,11.2,0.3c3.2,1.9,3.2,4.9,0,6.8s-8.6,1.9-11.8,0s-3.2-4.9,0-6.8" />
        <path d="M-148.7,179.5c3.3-1.6,8.2-1.5,11.2,0.3c3.2,1.9,3.2,4.9,0,6.8c-3.2,1.9-8.6,1.9-11.8,0s-3.2-4.9,0-6.8" />
        <path
          d="M-185.3,62.9c-3.3,1.6-8.2,1.5-11.2-0.3c-3.2-1.9-3.2-4.9,0-6.8c3.2-1.9,8.6-1.9,11.8,0s3.2,4.9,0,6.8
		l23.6-0.2l82.9,47.9v27.3c3.3-1.6,8.2-1.5,11.2,0.3c3.2,1.9,3.2,4.9,0,6.8c-3.2,1.9-8.6,1.9-11.8,0s-3.2-4.9,0-6.8"
        />
        <path d="M-172.3,193.2c3.3-1.6,8.2-1.5,11.2,0.3c3.2,1.9,3.2,4.9,0,6.8c-3.2,1.9-8.6,1.9-11.8,0s-3.2-4.9,0-6.8" />
        <path
          d="M-55.5,124.2c-3.2,1.9-3.2,4.9,0,6.8c3.2,1.9,8.6,1.9,11.8,0s3.2-4.9,0-6.8c-3.1-1.8-8-1.9-11.2-0.3V96.7
		l-94.4-54.5h-70.8c-3.3,1.6-8.2,1.5-11.2-0.3c-3.2-1.9-3.2-4.9,0-6.8c3.2-1.9,8.6-1.9,11.8,0s3.2,4.9,0,6.8"
        />
        <polyline points="334.7,130.8 606.2,287.5 606.2,546.5 977,756.1 	" />
        <polyline points="311.1,144.4 570.7,294.3 573.7,568.6 898.3,756.1 	" />
        <polyline points="289,158.9 534.8,301.5 534.8,587.1 827.5,756.1 	" />
        <polyline points="263.8,171.8 499.9,308 503.8,609.5 756.1,756.1 	" />
        <polyline points="240.6,186.7 464.4,314.9 464.4,630 687.1,756.1 	" />
        <path
          d="M26.9,335.2c-3.2-1.9-3.2-4.9,0-6.8s8.6-1.9,11.8,0s3.2,4.9,0,6.8c-3.1,1.8-8,1.9-11.2,0.3v-0.1l-35.1,20.3
		h-377.9l-413.1,238.5l-0.4,160.1"
        />
        <path
          d="M168.3,254.2c3.2,1.9,3.2,4.9,0,6.8s-8.6,1.9-11.8,0s-3.2-4.9,0-6.8c3.1-1.8,8-1.9,11.2-0.3l71.9-41.6
		l-23.1-13.4"
        />
        <path
          d="M-8.2,328.1c-3.3-1.6-8.2-1.5-11.2,0.3c-3.2,1.9-3.2,4.9,0,6.8s8.6,1.9,11.8,0s3.2-4.9,0-6.8l47.8-26.9h35
		c3.3-1.6,8.2-1.5,11.2,0.3c3.2,1.9,3.2,4.9,0,6.8s-8.6,1.9-11.8,0c-3.2-1.9-3.2-4.9,0-6.8"
        />
        <path
          d="M109.7,281c-3.2,1.9-3.2,4.9,0,6.8s8.6,1.9,11.8,0c3.2-1.9,3.2-4.9,0-6.8c-3.1-1.8-8-1.9-11.2-0.3H27.8
		l-59,34.1l-379.7,1.1l-458.4,265v175"
        />
        <path
          d="M204.3,464.4c-3.2-1.9-3.2-4.9,0-6.8s8.6-1.9,11.8,0s3.2,4.9,0,6.8c-3.1,1.8-8,1.9-11.2,0.3l-23.6,13.6
		l-47.1,0.1L-43.2,376.2l-330.4-1.8l-401.3,233.4v146.5"
        />
        <path
          d="M-745.4,754.3V619.7l383.7-223.1h306.8l177.1,102.2h70.9l59-34.1v-20.1c-3.3,1.6-8.2,1.5-11.2-0.3
		c-3.2-1.9-3.2-4.9,0-6.8s8.6-1.9,11.8,0s3.2,4.9,0,6.8"
        />
        <path
          d="M299.8,471.5c3.2-1.9,3.2-4.9,0-6.8s-8.6-1.9-11.8,0s-3.2,4.9,0,6.8c3.1,1.8,8,1.9,11.2,0.3V492l-47,27.3
		L251.6,628l221.8,128.1"
        />
        <path
          d="M615,756.1L299.3,573.8l-0.1-27.2l47.1-27.4v-20.1c-3.3,1.6-8.2,1.5-11.2-0.3c-3.2-1.9-3.2-4.9,0-6.8
		s8.6-1.9,11.8,0s3.2,4.9,0,6.8"
        />
        <path
          d="M251.5,491.6c-3.2-1.9-3.2-4.9,0-6.8s8.6-1.9,11.8,0c3.2,1.9,3.2,4.9,0,6.8c-3.1,1.8-8,1.9-11.2,0.3
		l-47.3,27.3h-94.4L-66.7,417H-350l-365.9,211.3v126"
        />
        <path
          d="M298.7,519c-3.2-1.9-3.2-4.9,0-6.8c3.2-1.9,8.6-1.9,11.8,0c3.2,1.9,3.2,4.9,0,6.8c-3.1,1.8-8,1.9-11.2,0.3
		L275.6,533v66.4L544,756.1"
        />
        <polyline points="218.6,7.8 452.7,-128.2 1330.5,-127.8 1793.6,-392.3 	" />
        <path
          d="M1459.7-66.8c-3.2-1.6-8.1-1.5-11.2,0.3c-3.2,1.9-3.2,4.9,0,6.8s8.6,1.9,11.8,0c3.2-1.9,3.2-4.9,0-6.8
		l570.3-325.8"
        />
        <path d="M1496-59.7c-3.2-1.9-3.2-4.9,0-6.8c3.2-1.9,8.6-1.9,11.8,0c3.2,1.9,3.2,4.9,0,6.8c-3,1.8-7.9,1.9-11.2,0.3" />
        <path d="M1519.2-46.1c-3.2-1.9-3.2-4.9,0-6.8c3.2-1.9,8.6-1.9,11.8,0c3.2,1.9,3.2,4.9,0,6.8c-3,1.8-7.9,1.9-11.2,0.3" />
        <path d="M1542.9-32.5c-3.2-1.9-3.2-4.9,0-6.8c3.2-1.9,8.6-1.9,11.8,0c3.2,1.9,3.2,4.9,0,6.8c-3,1.8-7.9,1.9-11.2,0.3" />
        <polyline points="1841.2,-392.3 1342.3,-107.4 464.2,-108.1 242.3,21.4 	" />
        <polyline points="1889.5,-392.3 1354.1,-87 477.8,-87.3 265.8,35 	" />
        <polyline points="1936.7,-392.3 1365.9,-66.5 486.9,-66.9 289.5,48.7 	" />
        <polyline points="1983.5,-392.3 1377.7,-46 498.1,-46.4 313,62.3 	" />
        <path
          d="M1459.7-66.8c-3.2-1.6-8.1-1.5-11.2,0.3c-3.2,1.9-3.2,4.9,0,6.8s8.6,1.9,11.8,0c3.2-1.9,3.2-4.9,0-6.8
		l570.3-325.8"
        />
        <path
          d="M1471.6-46.3c-3.2-1.9-3.2-4.9,0-6.8c3.2-1.9,8.6-1.9,11.8,0c3.2,1.9,3.2,4.9,0,6.8c-3.1,1.8-8,1.9-11.2,0.3
		l-35.4,20.4L511.7-26L336.6,75.9"
        />
        <path
          d="M1040,756.1L641.6,526V280.7L439.9,164.3l1-122.1l82.7-47.7l925,0.4l47.2-27.3c3.2,1.6,8.1,1.5,11.2-0.3
		c3.2-1.9,3.2-4.9,0-6.8c-3.2-1.9-8.6-1.9-11.8,0c-3.2,1.9-3.2,4.9,0,6.8"
        />
        <path
          d="M1110.8,756.1L677,505.6V273.9L464.4,151.2l0.1-95.4l69.3-41.7l926.6,1.2l59-34.1c3.2,1.6,8.1,1.5,11.2-0.3
		c3.2-1.9,3.2-4.9,0-6.8c-3.2-1.9-8.6-1.9-11.8,0c-3.2,1.9-3.2,4.9,0,6.8"
        />
        <path d="M1496-59.7c-3.2-1.9-3.2-4.9,0-6.8c3.2-1.9,8.6-1.9,11.8,0c3.2,1.9,3.2,4.9,0,6.8c-3,1.8-7.9,1.9-11.2,0.3" />
        <path d="M1519.2-46.1c-3.2-1.9-3.2-4.9,0-6.8c3.2-1.9,8.6-1.9,11.8,0c3.2,1.9,3.2,4.9,0,6.8c-3,1.8-7.9,1.9-11.2,0.3" />
        <path d="M1542.9-32.5c-3.2-1.9-3.2-4.9,0-6.8c3.2-1.9,8.6-1.9,11.8,0c3.2,1.9,3.2,4.9,0,6.8c-3,1.8-7.9,1.9-11.2,0.3" />
      </g>
      <g className="chip">
        <g className="chip__legs">
          <path className="leg-color-1" d="M5.8,76l7.8,4.5l3.2-4.5l-5.5-3.2L5.8,76z" />
          <path className="leg-color-1" d="M20,71.5l16.6,9.6l5.5-3.2l-16.5-9.4L20,71.5z" />
          <path className="leg-color-1" d="M14.6,83.6l5.8-3.1l3.1-4.5l-2.4-1.2L14.6,83.6z" />
          <path className="leg-color-2" d="M13.6,80.5L5.8,76v2.5l8.8,5.1l6.5-8.9l15.5,8.9v-2.5L20,71.5L13.6,80.5z" />
          <path className="leg-color-3" d="M36.6,81.1v2.5l5.5-3.2v-2.5L36.6,81.1z" />
          <path className="leg-color-1" d="M29,62.4l7.9,4.6l3.2-4.5l-5.5-3.2L29,62.4z" />
          <path className="leg-color-1" d="M43.3,58.1l16.5,9.5l5.5-3.1L49,54.9L43.3,58.1z" />
          <path className="leg-color-1" d="M37.9,70.1l5.8-3.1l3-4.5l-2.3-1.3L37.9,70.1z" />
          <path className="leg-color-2" d="M36.9,67L29,62.4v2.5l8.9,5.1l6.5-8.9L59.8,70v-2.5L43.3,58L36.9,67z" />
          <path className="leg-color-3" d="M59.9,67.6v2.5l5.5-3.1v-2.5L59.9,67.6z" />
          <path className="leg-color-1" d="M52.4,49l7.9,4.5l3.2-4.5L58,45.8L52.4,49z" />
          <path className="leg-color-1" d="M66.7,44.5l16.5,9.6l5.5-3.2l-16.4-9.5L66.7,44.5z" />
          <path className="leg-color-1" d="M61.3,56.6l5.8-3.1l3-4.5l-2.3-1.3L61.3,56.6z" />
          <path className="leg-color-2" d="M60.3,53.5L52.4,49v2.5l8.9,5.1l6.5-8.9l15.4,8.9v-2.5l-16.5-9.6L60.3,53.5z" />
          <path className="leg-color-3" d="M83.2,54.1v2.5l5.5-3.2v-2.5L83.2,54.1z" />
          <path className="leg-color-1" d="M75.7,35.4l7.9,4.5l3.2-4.4l-5.5-3.2L75.7,35.4z" />
          <path className="leg-color-1" d="M90,31l16.5,9.6l5.5-3.1L95.6,28L90,31z" />
          <path className="leg-color-1" d="M84.6,43.1l5.7-3.1l3.1-4.5l-2.3-1.3L84.6,43.1z" />
          <path className="leg-color-2" d="M83.6,39.9l-7.9-4.5v2.5l8.9,5.1l6.5-8.9l15.4,8.9v-2.5L90,31L83.6,39.9z" />
          <path className="leg-color-3" d="M106.5,40.6v2.5l5.5-3.2v-2.4L106.5,40.6z" />
          <path className="leg-color-1" d="M99,22l7.8,4.5L110,22l-5.5-3.2L99,22z" />
          <path className="leg-color-1" d="M113.3,17.5l16.5,9.6l5.5-3.2L119,14.4L113.3,17.5z" />
          <path className="leg-color-1" d="M107.9,29.6l5.7-3.1l3.1-4.5l-2.3-1.3L107.9,29.6z" />
          <path className="leg-color-2" d="M106.9,26.5L99,22v2.4l8.9,5.2l6.5-8.9l15.4,8.9v-2.4l-16.5-9.6L106.9,26.5z" />
          <path className="leg-color-3" d="M129.9,27.1v2.4l5.5-3.1v-2.5L129.9,27.1z" />
          <path className="leg-color-1" d="M122.4,8.5l7.8,4.5l3.2-4.5l-5.5-3.2L122.4,8.5z" />
          <path className="leg-color-1" d="M136.6,4l16.6,9.6l5.5-3.2L142.3,1L136.6,4z" />
          <path className="leg-color-1" d="M131.3,16.1L137,13l3.1-4.5l-2.4-1.2L131.3,16.1z" />
          <path className="leg-color-2" d="M130.2,13l-7.8-4.5V11l8.9,5.1l6.4-8.9l15.5,8.9v-2.5L136.6,4L130.2,13z" />
          <path className="leg-color-3" d="M153.2,13.6v2.5l5.5-3.2v-2.5L153.2,13.6z" />
        </g>
        <g className="chip__legs">
          <path className="leg-color-1" d="M335.2,75.3l-7.9,4.6l-3.2-4.5l5.5-3.2L335.2,75.3z" />
          <path className="leg-color-1" d="M321,70.9l-16.5,9.5l-5.6-3.1l16.5-9.5L321,70.9z" />
          <path className="leg-color-1" d="M326.4,82.9l-5.8-3.1l-3.1-4.5l2.4-1.3L326.4,82.9z" />
          <path
            className="leg-color-2"
            d="M327.4,79.8l7.8-4.6v2.5l-8.8,5.1l-6.5-8.9l-15.5,8.9l0.1-2.5l16.5-9.5L327.4,79.8z"
          />
          <path className="leg-color-3" d="M304.4,80.4l-0.1,2.5l-5.5-3.1v-2.5L304.4,80.4z" />
          <path className="leg-color-1" d="M311.9,61.8l-7.9,4.5l-3.2-4.5l5.6-3.2L311.9,61.8z" />
          <path className="leg-color-1" d="M297.6,57.4L281.1,67l-5.5-3.2l16.4-9.5L297.6,57.4z" />
          <path className="leg-color-1" d="M303,69.4l-5.8-3.1l-3-4.5l2.3-1.3L303,69.4z" />
          <path
            className="leg-color-2"
            d="M304,66.3l7.9-4.5l-0.1,2.5l-8.8,5.1l-6.5-8.9l-15.4,8.9v-2.5l16.5-9.6L304,66.3z"
          />
          <path className="leg-color-3" d="M281.1,66.9v2.5l-5.6-3.2l0.1-2.5L281.1,66.9z" />
          <path className="leg-color-1" d="M288.6,48.3l-7.9,4.5l-3.2-4.5l5.5-3.2L288.6,48.3z" />
          <path className="leg-color-1" d="M274.3,43.8l-16.5,9.6l-5.5-3.2l16.4-9.4L274.3,43.8z" />
          <path className="leg-color-1" d="M279.7,55.9l-5.8-3.1l-3-4.6l2.3-1.2L279.7,55.9z" />
          <path
            className="leg-color-2"
            d="M280.7,52.8l7.9-4.5v2.5l-8.9,5.1l-6.5-8.9l-15.4,8.9v-2.5l16.5-9.6L280.7,52.8z"
          />
          <path className="leg-color-3" d="M257.8,53.4v2.5l-5.5-3.2v-2.5L257.8,53.4z" />
          <path className="leg-color-1" d="M265.3,34.7l-7.9,4.6l-3.2-4.5l5.5-3.2L265.3,34.7z" />
          <path className="leg-color-1" d="M251,30.4l-16.5,9.5l-5.5-3.1l16.4-9.5L251,30.4z" />
          <path className="leg-color-1" d="M256.4,42.4l-5.7-3.1l-3.1-4.5l2.3-1.3L256.4,42.4z" />
          <path
            className="leg-color-2"
            d="M257.4,39.3l7.8-4.6v2.5l-8.9,5.2l-6.5-8.9l-15.4,8.9v-2.5l16.5-9.5L257.4,39.3z"
          />
          <path className="leg-color-3" d="M234.4,39.9v2.5l-5.5-3.1v-2.5L234.4,39.9z" />
          <path className="leg-color-1" d="M241.9,21.3l-7.8,4.5l-3.2-4.5l5.5-3.2L241.9,21.3z" />
          <path className="leg-color-1" d="M227.6,16.8l-16.5,9.6l-5.5-3.2l16.4-9.4L227.6,16.8z" />
          <path className="leg-color-1" d="M233,28.9l-5.7-3.1l-3.1-4.5l2.3-1.2L233,28.9z" />
          <path
            className="leg-color-2"
            d="M234.1,25.8l7.8-4.5v2.5l-8.9,5.1l-6.5-8.9l-15.4,8.9v-2.5l16.5-9.6L234.1,25.8z"
          />
          <path className="leg-color-3" d="M211.1,26.4v2.5l-5.5-3.2v-2.5L211.1,26.4z" />
          <path className="leg-color-1" d="M218.6,7.8l-7.8,4.6l-3.2-4.5l5.5-3.2L218.6,7.8z" />
          <path className="leg-color-1" d="M204.4,3.4l-16.6,9.5l-5.5-3.1l16.4-9.5L204.4,3.4z" />
          <path className="leg-color-1" d="M209.7,15.4l-5.7-3.1l-3.1-4.5l2.4-1.3L209.7,15.4z" />
          <path
            className="leg-color-2"
            d="M210.7,12.3l7.8-4.6v2.5l-8.9,5.1l-6.4-8.9l-15.5,8.9v-2.5l16.6-9.5L210.7,12.3z"
          />
          <path className="leg-color-3" d="M187.8,12.9v2.5l-5.5-3.1V9.8L187.8,12.9z" />
        </g>
        <g className="chip__body">
          <g className="chip__body__bottom">
            <path className="chip-bottom-color-3" d="M315.5,98.3l-144.4,83l-147.2-84l144.4-83L315.5,98.3z" />
          </g>
          <g className="chip__body__middle">
            <path className="chip-middle-color-1" d="M315.7,84.3l-144.4,83l-147.1-84l144.4-83L315.7,84.3z" />
            <path className="chip-middle-color-2" d="M315.7,84.3l-0.1,9.3l-144.4,83l0.1-9.3L315.7,84.3z" />
            <path className="chip-middle-color-3" d="M171.4,167.4l-0.1,9.3L24.2,92.6v-9.3L171.4,167.4z" />
          </g>
          <g className="chip__body__top">
            <path className="chip-top-color-1" d="M281,73.7V83l-109.3,62.9l0.1-9.3L281,73.7z" />
            <path className="chip-top-color-2" d="M171.8,136.6l-0.1,9.3L60.4,82.2V73L171.8,136.6z" />
            <path className="chip-top-color-3" d="M281,73.7l-109.3,62.9L60.4,73l109.3-62.9L281,73.7z" />
          </g>
        </g>
        <g className="chip__legs">
          <path className="leg-color-3" d="M26,105.7l-4,15.9l-5.5-3.2l4.1-15.8L26,105.7z" />
          <path className="leg-color-1" d="M22,121.6l-13.7,7.8l-5.5-3.2l13.7-7.8L22,121.6z" />
          <path className="leg-color-2" d="M26,105.7l14.8-8.5v2.5l-13.1,7.6l-3.9,15.8L8.3,132v-2.5l13.7-7.8" />
          <path className="leg-color-3" d="M8.3,129.4v2.5l-5.5-3.2v-2.5L8.3,129.4z" />
          <path className="leg-color-1" d="M64.1,110.7l-14.9,8.5l-5.5-3.2l14.9-8.5L64.1,110.7z" />
          <path className="leg-color-3" d="M49.3,119.2l-4,15.8l-5.5-3.1l4-15.9L49.3,119.2z" />
          <path className="leg-color-1" d="M45.3,135.1L31.6,143l-5.5-3.2l13.7-7.8L45.3,135.1z" />
          <path className="leg-color-2" d="M49.3,119.2l14.9-8.5v2.5L51,120.7l-4,15.8l-15.4,8.9V143l13.7-7.9" />
          <path className="leg-color-3" d="M31.6,143v2.4l-5.5-3.1v-2.5L31.6,143z" />
          <path className="leg-color-1" d="M87.5,124.2l-14.9,8.6l-5.5-3.2l14.9-8.5L87.5,124.2z" />
          <path className="leg-color-3" d="M72.6,132.8l-4,15.8l-5.5-3.2l4-15.8L72.6,132.8z" />
          <path className="leg-color-1" d="M68.6,148.6L55,156.4l-5.5-3.2l13.7-7.8L68.6,148.6z" />
          <path className="leg-color-2" d="M72.6,132.8l14.9-8.6v2.5l-13.2,7.6L70.4,150L55,158.9v-2.5l13.7-7.8" />
          <path className="leg-color-3" d="M55,156.4v2.5l-5.5-3.2v-2.5L55,156.4z" />
          <path className="leg-color-1" d="M110.8,137.7L96,146.2l-5.5-3.1l14.9-8.6L110.8,137.7z" />
          <path className="leg-color-3" d="M96,146.2L91.9,162l-5.5-3.1l4.1-15.8L96,146.2z" />
          <path className="leg-color-1" d="M91.9,162l-13.6,8l-5.5-3.2l13.6-7.8L91.9,162z" />
          <path className="leg-color-2" d="M96,146.2l14.9-8.5l-0.1,2.5l-13.1,7.5l-3.9,15.8l-15.4,8.9V170l13.6-7.9" />
          <path className="leg-color-3" d="M78.3,170v2.5l-5.6-3.2l0.1-2.5L78.3,170z" />
          <path className="leg-color-1" d="M134.1,151.2l-14.8,8.6l-5.5-3.2l14.8-8.5L134.1,151.2z" />
          <path className="leg-color-3" d="M119.3,159.8l-4.1,15.8l-5.5-3.1l4.1-15.9L119.3,159.8z" />
          <path className="leg-color-1" d="M115.2,175.6l-13.6,7.8l-5.6-3.1l13.7-7.8L115.2,175.6z" />
          <path className="leg-color-2" d="M119.3,159.8l14.8-8.6v2.5l-13.1,7.6l-4,15.7l-15.5,8.9l0.1-2.5l13.6-7.8" />
          <path className="leg-color-3" d="M101.6,183.4l-0.1,2.5l-5.5-3.1v-2.5L101.6,183.4z" />
          <path className="leg-color-1" d="M157.4,164.7l-14.8,8.5l-5.5-3.1l14.8-8.5L157.4,164.7z" />
          <path className="leg-color-3" d="M142.6,173.2l-4.1,15.9l-5.5-3.2l4.1-15.8L142.6,173.2z" />
          <path className="leg-color-1" d="M138.6,189.1l-13.7,7.8l-5.5-3.2l13.7-7.8L138.6,189.1z" />
          <path className="leg-color-2" d="M142.6,173.2l14.8-8.5v2.5l-13.1,7.6l-3.9,15.8l-15.5,8.9V197l13.7-7.8" />
          <path className="leg-color-3" d="M124.9,196.9v2.5l-5.5-3.2v-2.5L124.9,196.9z" />
          <path className="leg-color-1" d="M40.8,97.2L26,105.7l-5.5-3l14.1-8.5L40.8,97.2z" />
          <path className="leg-color-1" d="M298.7,98l14.8,8.5l5.5-3.1l-14.8-8.6L298.7,98z" />
          <path className="leg-color-3" d="M313.5,106.5l4.1,15.9l5.5-3.2l-4.1-15.8L313.5,106.5z" />
          <path className="leg-color-1" d="M317.5,122.4l13.7,7.8l5.5-3.2l-13.7-7.8L317.5,122.4z" />
          <path className="leg-color-2" d="M313.5,106.5L298.7,98v2.5l13.1,7.6l3.9,15.8l15.5,8.9v-2.5l-13.7-7.8" />
          <path className="leg-color-3" d="M331.2,130.3v2.5l5.5-3.2v-2.5L331.2,130.3z" />
          <path className="leg-color-1" d="M275.4,111.5l14.8,8.5l5.5-3.2l-14.8-8.5L275.4,111.5z" />
          <path className="leg-color-3" d="M290.1,120.1l4.1,15.8l5.5-3.1l-4.1-15.9L290.1,120.1z" />
          <path className="leg-color-1" d="M294.2,135.9l13.7,7.9l5.5-3.2l-13.7-7.8L294.2,135.9z" />
          <path className="leg-color-2" d="M290.1,120.1l-14.8-8.5v2.5l13.1,7.5l4,15.8l15.4,8.9v-2.5l-13.7-7.9" />
          <path className="leg-color-3" d="M307.9,143.8v2.5l5.5-3.2v-2.5L307.9,143.8z" />
          <path className="leg-color-1" d="M252,125l14.9,8.6l5.5-3.2l-14.9-8.5L252,125z" />
          <path className="leg-color-3" d="M266.9,133.6l4,15.8l5.5-3.2l-4-15.8L266.9,133.6z" />
          <path className="leg-color-1" d="M270.9,149.4l13.7,7.8l5.5-3.1l-13.7-7.9L270.9,149.4z" />
          <path className="leg-color-2" d="M266.9,133.6L252,125v2.5l13.1,7.6l4,15.8l15.4,8.9v-2.5l-13.7-7.8" />
          <path className="leg-color-3" d="M284.5,157.3v2.5l5.5-3.1v-2.5L284.5,157.3z" />
          <path className="leg-color-1" d="M228.7,138.5l14.9,8.5l5.5-3.1l-14.9-8.6L228.7,138.5z" />
          <path className="leg-color-3" d="M243.5,147.1l4,15.9l5.6-3.2L249,144L243.5,147.1z" />
          <path className="leg-color-1" d="M247.5,162.9l13.7,7.8l5.5-3.2l-13.6-7.8L247.5,162.9z" />
          <path className="leg-color-2" d="M243.5,147.1l-14.9-8.5v2.5l13.2,7.6l3.9,15.7l15.4,8.9v-2.5l-13.7-7.8" />
          <path className="leg-color-3" d="M261.2,170.8v2.5l5.5-3.2v-2.5L261.2,170.8z" />
          <path className="leg-color-1" d="M205.3,152.1l14.9,8.5l5.5-3.2l-14.9-8.5L205.3,152.1z" />
          <path className="leg-color-3" d="M220.2,160.6l4.1,15.8l5.5-3.1l-4.1-15.9L220.2,160.6z" />
          <path className="leg-color-1" d="M224.3,176.4l13.6,7.8l5.5-3.1l-13.6-7.8L224.3,176.4z" />
          <path className="leg-color-2" d="M220.2,160.6l-14.9-8.5v2.4l13.2,7.6l3.9,15.8l15.4,8.9v-2.5l-13.6-7.8" />
          <path className="leg-color-3" d="M237.9,184.2v2.5l5.5-3.1v-2.5L237.9,184.2z" />
          <path className="leg-color-1" d="M182.1,165.5l14.8,8.5l5.5-3.1l-14.8-8.5L182.1,165.5z" />
          <path className="leg-color-3" d="M196.9,174l4.1,15.9l5.5-3.2l-4.1-15.8L196.9,174z" />
          <path className="leg-color-1" d="M200.9,189.9l13.7,7.8l5.5-3.2l-13.7-7.8L200.9,189.9z" />
          <path className="leg-color-2" d="M196.9,174l-14.8-8.5v2.5l13.1,7.6l3.9,15.8l15.5,8.9v-2.5l-13.7-7.8" />
          <path className="leg-color-3" d="M214.6,197.8v2.5l5.5-3.2v-2.5L214.6,197.8z" />
        </g>
      </g>
    </svg>
  )
}
