import { AccordionItem } from '@szhsin/react-accordion'
import { useTranslation } from 'react-i18next'

import { FeedbackForm } from './FeedbackForm.tsx'

export const FeedbackFormAccordion = (props: {
  feedbackRef: any
  inputValue: string
  requestId?: string | null
  userId?: string | null
}) => {
  const { t } = useTranslation()

  return (
    <AccordionItem header={t('contact.title')} itemKey="accordion-feedback" ref={props.feedbackRef}>
      <FeedbackForm inputValue={props.inputValue} requestId={props.requestId} userId={props.userId} />
    </AccordionItem>
  )
}
