import { AccordionProviderValue, ItemKey } from '@szhsin/react-accordion/types/utils/constants'
import { MutableRefObject, RefObject, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowRight } from '../ArrowRight.tsx'
import { FeedbackForm } from './FeedbackForm.tsx'
import GlobalContext from './GlobalContext.tsx'

export const QuickFeedback = (props: {
  providerValue: AccordionProviderValue
  questionRef: MutableRefObject<null>
  feedbackRef: MutableRefObject<null>
  hintRef: MutableRefObject<null>
  requestId?: string | null
  userId?: string | null
  inputValue: string
}) => {
  const { t } = useTranslation()
  const [wasUseful, setwasUseful] = useState(false)
  const [wasNotUseful, setwasNotUseful] = useState(false)
  const { host, org } = useContext(GlobalContext)

  const { toggle } = props.providerValue

  const setFocusOnQuestion = (questionRef: any) => {
    questionRef.current?.querySelector('textarea')?.focus()
    questionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const handleFeedback = async (feedback: string) => {
    try {
      if (feedback === 'positive') {
        setwasUseful(true)
        setwasNotUseful(false)
      } else {
        setwasUseful(false)
        setwasNotUseful(true)
      }

      const body: Record<string, string> = {
        feedback,
        rID: props.requestId || '',
      }

      await fetch(`${host}/${org}/quickfeedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
    } catch (error) {
      console.log('Error sending feedback data:', error)
    }
  }

  const openAccordion = (accordion: ItemKey, inputRef: RefObject<HTMLTextAreaElement>) => {
    toggle(accordion, true)
    setTimeout(() => {
      inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, 450)
  }
  return (
    <div className="box">
      <div className="box__content">
        <span>{t('answers.wasItUseful')}</span>
        <button
          className="button button--small"
          onClick={() => {
            handleFeedback('positive')
          }}
        >
          <span>{t('answers.yes')}</span>
        </button>
        <button
          className="button button--small"
          onClick={() => {
            handleFeedback('negative')
          }}
        >
          <span>{t('answers.no')}</span>
        </button>
      </div>
      <div className={`box__drawer ${wasUseful ? 'box__drawer--is-open' : ''}`}>
        <div className="box__drawer__content">{t('answers.thanksForFeedback')}</div>
      </div>
      <div className={`box__drawer ${wasNotUseful ? 'box__drawer--is-open' : ''}`}>
        <div className="box__drawer__content">
          <div>{t('answers.letsTryToImprove')}</div>
          <button className="button button--bare" onClick={() => setFocusOnQuestion(props.questionRef)}>
            <span>{t('answers.improvePrompt')}</span>
            <ArrowRight />
          </button>
          <button className="button button--bare" onClick={() => openAccordion('accordion-hints', props.hintRef)}>
            <span>{t('answers.readHints')}</span>
            <ArrowRight />
          </button>
          <button
            className="button button--bare"
            onClick={() => openAccordion('accordion-feedback', props.feedbackRef)}
          >
            <span>{t('answers.giveFeedback')}</span>
            <ArrowRight />
          </button>
          <FeedbackForm inputValue={props.inputValue} requestId={props.requestId} userId={props.userId} />
        </div>
      </div>
    </div>
  )
}
