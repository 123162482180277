import { Tooltip as ReactTooltip } from 'react-tooltip'

import { isMobile } from '../helpers.ts'

export const Tooltip = () => (
  <ReactTooltip
    anchorSelect=".my-anchor-element"
    place="top"
    opacity={1}
    offset={12}
    className="tooltip"
    classNameArrow="arrow"
    openOnClick={isMobile}
    clickable={true}
  ></ReactTooltip>
)
