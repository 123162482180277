export const Microphone = () => {
  return (
    <svg
      className="microphone"
      fill="none"
      height="42"
      viewBox="0 0 46 42"
      width="46"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m27.8346 23.2083c0 2.7662-2.1914 4.9584-4.8333 4.9584s-4.8333-2.1922-4.8333-4.9584v-7.75c0-2.7514 2.2059-4.9583 4.8333-4.9583s4.8333 2.2069 4.8333 4.9583z" />
      <path d="m23 39v-4" />
      <path d="m34.6673 23c0 6.6274-5.2233 12-11.6666 12-6.4434 0-11.6667-5.3726-11.6667-12" />
    </svg>
  )
}
