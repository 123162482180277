import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import GlobalContext from './GlobalContext.tsx'

export const FeedbackForm = (props: { inputValue: string; requestId?: string | null; userId?: string | null }) => {
  const { t } = useTranslation()
  const [feedbackValue, setFeedbackValue] = useState('')
  const [feedbackReceived, setFeedbackReceived] = useState('')
  const [emailValue, setEmailValue] = useState(sessionStorage.getItem('email') || '')
  const { host, org } = useContext(GlobalContext)

  const handleFeedback = async (event: React.FormEvent) => {
    event.preventDefault()
    try {
      if (!feedbackValue) {
        return
      }
      setFeedbackReceived('')

      const body: Record<string, string> = {
        query: props.inputValue || 'Keine im Inputfeld',
        email: emailValue,
        body: feedbackValue,
      }
      sessionStorage.setItem('email', emailValue)
      if (props.requestId) {
        body.rID = props.requestId
      }
      if (props.userId) {
        body.uID = props.userId
      }
      try {
        const response = await fetch(`${host}/${org}/feedback`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
        const data = await response.text()
        if (data === 'OK') {
          setFeedbackValue('')
          setFeedbackReceived('OK')
        } else {
          setFeedbackReceived('ERROR')
        }
      } catch (e) {
        setFeedbackReceived('ERROR')
      }
    } catch (error) {
      console.log('Error sending feedback data:', error)
    }
  }

  return (
    <div>
      <form onSubmit={handleFeedback} className="form">
        <label htmlFor="feedback">{t('contact.messageLabel')}</label>
        <textarea
          id="feedback"
          value={feedbackValue}
          onChange={event => setFeedbackValue(event.target.value)}
          style={{ height: 'auto', width: '100%', maxWidth: '700px' }}
        ></textarea>
        <label htmlFor="email">{t('contact.emailLabel')}</label>
        <input
          id="email"
          value={emailValue}
          onChange={event => setEmailValue(event.target.value)}
          style={{ height: 'auto', width: '100%' }}
        />
        <button type="submit" className="button" disabled={!feedbackValue}>
          {t('contact.submitButton')}
        </button>
        {feedbackReceived === 'OK' && <span>{t('contact.feedbackReceived')}</span>}
        {feedbackReceived === 'ERROR' && <span>{t('contact.feedbackReceivedError')}</span>}
      </form>
      <div>{t('contact.emailText')}</div>
      {t('contact.moreContact')}{' '}
      <a href="https://www.liip.ch/team/chregu" target="_blank" rel="noopener noreferrer">
        liip.ch/team/chregu
      </a>
    </div>
  )
}
