export class TypingMessageQueue {
  private queue: string[] = []
  private thisIsTheEnd = false
  private startTime: number = performance.now()
  private counter: number = 0
  private average: number = 10
  constructor(private setQueryResult: any) {
    this.start()
  }

  // Adds a message to the queue
  enqueue(message: string): void {
    if (message === '__CLR__') {
      this.counter = 0
      this.startTime = performance.now()
    }
    this.counter++

    this.average = (performance.now() - this.startTime) / this.counter

    this.queue.push(message)
  }

  // Removes a message from the queue and returns it
  dequeue(): string | null {
    if (this.queue.length === 0) {
      return null
    }
    return this.queue.shift() as string
  }

  private start(timeout: number = 100): void {
    const startTimeout = (timeout: number = 100) => {
      setTimeout(() => {
        writeMessage()
      }, timeout)
    }
    const writeMessage = () => {
      const message = this.dequeue()
      // stop
      if (message === '__THIS_IS_THE_END__') {
        return
      }
      if (message === '__CLR__') {
        this.setQueryResult(() => {
          return ' '
        })
        writeMessage()
        return
      }

      if (message !== null) {
        this.setQueryResult((queryResult: string) => {
          return queryResult.concat(message)
        })
      }
      if (!this.queue.length) {
        startTimeout(50)
        return
      }

      // immediatly write the next message if there are more than 70 messages in the queue or if we reached the end
      if (this.thisIsTheEnd || this.queue.length > 70) {
        writeMessage()
        return
      }
      const t = Math.max(0, this.average - 5)
      if (t < 0.5) {
        writeMessage()
        return
      }
      startTimeout(t)
    }

    startTimeout(timeout)
  }

  setThisIsTheEnd() {
    this.thisIsTheEnd = true
  }
}
