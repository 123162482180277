import i18n from 'i18next'
import React, { ReactNode, useState } from 'react'

import { defaultLanguage } from '../i18n.ts'

interface GlobalContextInterface {
  mainLanguage: string
  setMainLanguage: (lang: string) => void
  langs: { [key: string]: { nativeName: string } }
  host: string
  org: string
}

const GlobalContext = React.createContext<GlobalContextInterface>({
  mainLanguage: 'de',
  setMainLanguage: () => {},
  langs: {},
  host: '',
  org: '',
})

export const GlobalContextProvider = ({ children }: { children: ReactNode }) => {
  const langs: { [key: string]: { nativeName: string } } = {
    en: { nativeName: 'English' },
    de: { nativeName: 'Deutsch' },
    fr: { nativeName: 'Français' },
  }
  const mainLanguageDetected = i18n.language.split('-')[0]
  const org = import.meta.env.VITE_ORG_NAME

  const host = window.location.hostname.includes('localhost')
    ? 'http://localhost:3000'
    : `https://${org}-api.gpt.liip.ch`

  const [mainLanguage, setMainLanguage] = useState(langs[mainLanguageDetected] ? mainLanguageDetected : defaultLanguage)
  return (
    <GlobalContext.Provider value={{ mainLanguage, setMainLanguage, langs, host, org }}>
      {children}
    </GlobalContext.Provider>
  )
}
export default GlobalContext
