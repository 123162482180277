import React, { useEffect, useState } from 'react'

const createAudioUrl = (blob: Blob): string => {
  const url = URL.createObjectURL(blob)
  return url
}

type AudioPlayerProps = {
  fetchAudio: () => Promise<Blob>
}

const AudioPlayer: React.FC<AudioPlayerProps> = React.memo(({ fetchAudio }) => {
  const [audioSrc, setAudioSrc] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  useEffect(() => {
    let isMounted = true

    const loadAudio = async () => {
      try {
        const audioBlob = await fetchAudio()
        if (isMounted) {
          const url = createAudioUrl(audioBlob)
          setIsLoading(false)
          setAudioSrc(url)
        }
      } catch (error) {
        console.error('Failed to load audio', error)
      }
    }

    loadAudio()
    setIsLoading(true)
    return () => {
      isMounted = false
      if (audioSrc) {
        URL.revokeObjectURL(audioSrc)
      }
    }
  }, [])

  return (
    <div>
      <audio controls autoPlay={true}>
        {audioSrc && <source src={audioSrc} type="audio/mpeg" />}
        Your browser does not support the audio element.
      </audio>
      {isLoading && <div>Loading...</div>}
    </div>
  )
})

export default AudioPlayer
