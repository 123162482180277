import { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import GlobalContext from './GlobalContext.tsx'

export const Footer = () => {
  const { t, i18n } = useTranslation()
  const { mainLanguage, langs, setMainLanguage } = useContext(GlobalContext)

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__notes">
          <p>
            <Trans i18nKey="footer.credits">
              Powered by
              <a href={'https://www.liip.ch'} target={'_blank'}>
                Liip
              </a>
              —
              <a
                href={'https://www.liip.ch/blog/ask-zuricitygpt-anything-about-the-government-of-the-city-of-zurich'}
                target={'_blank'}
              >
                Blog Post about the Project
              </a>
            </Trans>
          </p>
          <p>{t('footer.disclaimer')}</p>
        </div>
        <div className="footer__language">
          <label htmlFor="language-switcher" className="sr-only">
            {t('footer.selectLanguage')}
          </label>
          <div className="select">
            <select
              id="language-switcher"
              defaultValue={mainLanguage}
              className="button"
              onChange={e => {
                setMainLanguage(e.target.value)
                i18n.changeLanguage(e.target.value)
              }}
            >
              {Object.keys(langs).map(lng => (
                <option key={lng} value={lng}>
                  {langs[lng].nativeName}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </footer>
  )
}
