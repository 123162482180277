import { useCallback, useRef } from 'react'

export interface EventSourceMessage {
  data: string
}

export interface EventSourceHook {
  close: (name: string) => void
  start: (
    name: string,
    uri: string,
    setData: (data: EventSourceMessage) => void,
    setError: (error: Event) => void,
  ) => void
}

export const useEventSource = (): EventSourceHook => {
  const eventSourceRef = useRef<{ [key: string]: EventSource | null }>({})
  const closeEventSource = useCallback((name: string) => {
    eventSourceRef.current[name]?.close()
  }, [])

  const start = useCallback(
    (name: string, sseUri: string, setData: (data: EventSourceMessage) => void, setError: (error: Event) => void) => {
      // Close the existing connection when the URI changes
      closeEventSource(name)

      // Check if the URI is valid before creating a new EventSource
      if (sseUri && (!eventSourceRef.current[name] || sseUri !== eventSourceRef.current[name]?.url)) {
        const es = new EventSource(sseUri)
        eventSourceRef.current[name] = es

        es.onmessage = event => {
          setData(event)
        }

        es.onerror = event => {
          setError(event)
          console.log('EventSource failed:', event)
          closeEventSource(name)
        }

        // Return the cleanup function that closes the EventSource
        // when the component unmounts or the URI changes
        return () => {
          es.close()
        }
      }
    },
    [closeEventSource],
  )

  return { close: closeEventSource, start }
}
