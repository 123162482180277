import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { isMobile } from '../helpers.ts'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Microphone } from '../Microphone.tsx'
import { Picture } from '../Picture.tsx'
import { Vision } from '../Vision.tsx'
import GlobalContext from './GlobalContext.tsx'
const mediaType =
  window.MediaRecorder &&
  (MediaRecorder.isTypeSupported('audio/mp4')
    ? 'audio/mp4'
    : MediaRecorder.isTypeSupported('audio/webm')
      ? 'audio/webm'
      : MediaRecorder.isTypeSupported('audio/wav')
        ? 'audio/wav'
        : undefined)

const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: mimeString })
}

export const VisionButton = (props: {
  setInputValue: Dispatch<SetStateAction<string>>
  sendSubmit: (value: string) => void
  setIsLoading: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const { mainLanguage, host, org } = useContext(GlobalContext)
  const [imageSrc, setImageSrc] = useState<string | null>(null)
  const [isCameraActive, setIsCameraActive] = useState<boolean>(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const uploadImage = async (imageFile: Blob) => {
      props.setIsLoading(true)
      const formData = new FormData()
      formData.append('file', imageFile)

      try {
        const response = await fetch(host + `/${org}/vision?key=X9hL4Gp5W2D7eRtF&lang=${mainLanguage}`, {
          method: 'POST',
          body: formData,
        })

        if (response.ok) {
          const text = await response.text()
          console.log('Image uploaded successfully:', response)
          props.setInputValue(text)
          props.sendSubmit(text)
          // Handle the response as needed
        } else {
          props.setIsLoading(false)
          console.error('Failed to upload image:', response.statusText)
          // Handle errors as needed
        }
      } catch (error) {
        props.setIsLoading(false)
        console.error('An error occurred while uploading the image:', error)
        // Handle the error as needed
      }
    }
    if (imageSrc) {
      uploadImage(dataURItoBlob(imageSrc))
    }
  }, [host, mainLanguage, org, imageSrc])

  // Handle file input from the library
  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files[0]) {
      setIsCameraActive(false)
      const fileReader = new FileReader()
      fileReader.onload = (e: ProgressEvent<FileReader>) => {
        setImageSrc(e.target?.result as string)
      }
      fileReader.readAsDataURL(files[0])
    }
  }
  // Toggle camera stream
  const toggleCamera = useCallback((cameraActive: boolean) => {
    console.log('c', cameraActive)

    if (cameraActive) {
      // If camera is active, capture the image
      const videoElement = videoRef.current
      const canvasElement = canvasRef.current
      if (videoElement && canvasElement) {
        const context = canvasElement.getContext('2d')
        if (context) {
          const width = videoElement.videoWidth
          const height = videoElement.videoHeight
          canvasElement.width = width
          canvasElement.height = height
          context.drawImage(videoElement, 0, 0, width, height)
          setImageSrc(canvasElement.toDataURL('image/png'))
          // Optional: Turn off the camera after capturing the image
          ;(videoRef.current.srcObject as MediaStream).getTracks().forEach(track => track.stop())
        }
      }
      setIsCameraActive(false)
    } else {
      // If camera is not active, start the camera
      setIsCameraActive(true)

      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(stream => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream
          }
        })
        .catch(err => {
          console.error('Error accessing the camera', err)
          setIsCameraActive(false)
        })
    }
  }, [])
  // Stop the video stream when component unmounts
  useEffect(() => {
    return () => {
      if (videoRef.current?.srcObject) {
        ;(videoRef.current.srcObject as MediaStream).getTracks().forEach(track => track.stop())
      }
    }
  }, [isCameraActive])

  if (!mediaType) {
    return null
  }

  return (
    <>
      <input type="file" accept="image/*" style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileInput} />

      {isCameraActive && (
        <div className={'visionImage'}>
          <video ref={videoRef} autoPlay={true} style={{ width: '300px' }} />
          <canvas ref={canvasRef} style={{ display: 'none' }} />
        </div>
      )}
      {imageSrc && <img className={'visionImage'} style={{}} src={imageSrc} alt="Captured or Selected" />}
      <button
        type="button"
        className="button--microphone vision button"
        onClick={() => {
          fileInputRef.current?.click()
        }}
      >
        <span className="sr-only">{t('question.microphoneLabel')}</span>
        {isMobile ? <Vision /> : <Picture />}
        <div className="button__hint">Upload Image</div>
      </button>

      {!isMobile && (
        <button
          type="button"
          className="button--microphone vision button"
          onClick={() => {
            toggleCamera(isCameraActive)
          }}
        >
          <span className="sr-only">{t('question.microphoneLabel')}</span>
          <Vision />
          <div className="button__hint">Capture Image</div>
        </button>
      )}
    </>
  )
}
