import React, { useCallback, useContext, useState } from 'react'

import AudioPlayer from './AudioPlayer.tsx'
import GlobalContext from './GlobalContext.tsx'

type AudioPlayerProps = {
  text: string
}

const AudioPlayerWrapper: React.FC<AudioPlayerProps> = React.memo(({ text }) => {
  const [showPlayer, setShowPlayer] = useState<boolean>(false)
  const { host, org } = useContext(GlobalContext)

  const fetchAudio = useCallback(async (): Promise<Blob> => {
    // Add your request payload if necessary

    const response = await fetch(
      host + `/${org}/tts?key=X9hL4Gp5W2D7eRtF&text=${encodeURIComponent(text)}&voice=fable`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', // or the appropriate content type for your request
          // Include other headers as needed
        },
      },
    )

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`)
    }
    return response.blob()
  }, [host, org, text])
  return (
    <div>
      {!showPlayer && (
        <button className={'button button--small'} onClick={() => setShowPlayer(true)}>
          Load Audio
        </button>
      )}
      {showPlayer && <AudioPlayer fetchAudio={fetchAudio} />}
    </div>
  )
})

export default AudioPlayerWrapper
